<template style="width:100%">
  <div class="choiceCarModel">
    <van-sticky :offset-top="1"
      ><nav-bar
        :title="brandName + serviceName || title"
        :iconName="(current > 1 && 'arrow-left') || 'cross'"
        :clickLeft="onClickLeft"
        :hasLeftClick="true"
      >
      </nav-bar
    ></van-sticky>
    <div class="cpopup" v-show="current == 1">
      <!-- <van-icon name="cross" class="close" /> -->
      <!-- <div class="brand">汽车品牌</div> -->
      <!-- <van-search
        v-model="modelName"
        placeholder="请输入汽车品牌进行搜索"
        shape
        :clearable="false"
      >
        <template #left-icon>
          <div style="visibility: hidden; width: 0"></div>
        </template>
        <template #right-icon>
          <div class="icon" @click="search"><van-icon name="search" /></div>
        </template>
      </van-search> -->
      <van-index-bar :index-list="indexList" :sticky-offset-top="46" @change="indexBarChange" ref="scrollBar">
        <span v-for="(item, index) in brandList" :key="index">
          <van-index-anchor :index="indexList[index]" style="z-index: 99999999">{{
            indexList[index]
          }}</van-index-anchor>
          <van-grid>
            <van-grid-item
              v-for="(key, index) in item[indexList[index]]"
              :key="index"
              @click="brandClick(key)"
            >
              <div class="flex" style="flex-direction: column">
                <van-image
                  :src="key.url"
                  style="width: 35px; height: 35px"
                ></van-image>
                <div
                  style="text-align: center; font-size: 12px; margin-top: 5px"
                >
                  {{ key.brandName }}
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </span>
      </van-index-bar>
    </div>
    <div class="cpopup" v-show="current == 2">
      <!-- <van-icon name="arrow-left" class="close" @click="current = 1" /> -->
      <!-- <div class="brand">{{ brand }}</div> -->
      <div
        class="car van-hairline--bottom"
        v-for="(item, index) in seriesList"
        :key="index"
      >
        <div class="title">{{ item.groupName }}</div>
        <div
          class="item flex"
          v-for="(val, key) in item.serviceList"
          :key="key"
          @click="seriesClick(val)"
        >
          <div class="series">{{ val.serviceName }}</div>
          <div>
            <van-icon
              name="success"
              color="red"
              v-show="val.serviceId == series"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="cpopup" v-show="current == 3">
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="carYear"
          :options="yearList"
          @change="yearChange"
        />
        <van-dropdown-item
          v-model="carPl"
          :options="displacementList"
          @change="displacementChange"
        />
      </van-dropdown-menu>
      <div
        v-for="(item, index) in carModelList"
        :key="index"
        class="list van-hairline--bottom"
        @click="modelClick(item)"
      >
        {{ item.carModelName }}
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { getCarBrandList, getCarServiceList, getCarModelList } from "@/api";
import {
  Search,
  Icon,
  Popup,
  Tab,
  Tabs,
  Collapse,
  CollapseItem,
  Cell,
  Tag,
  IndexBar,
  IndexAnchor,
  Grid,
  GridItem,
  DropdownMenu,
  DropdownItem,
  Image,
  Toast,
  Sticky,
} from "vant";
export default {
  name: "choiceCarModel",
  components: {
    NavBar,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Toast.name]: Toast,
    [Image.name]: Image,
    [Sticky.name]: Sticky,
  },
  data() {
    return {
      brandList: [], //车型列表
      seriesList: [], //车系列表
      indexList: [],
      title: "选择车型",
      modelName: "", //汽车品牌搜索
      current: 1, //当前进行到第几步
      brand: "", //选择的品牌
      brandName: "",
      series: "", //选择的车系
      serviceName: "",
      yearList: [], //年款列表
      displacementList: [], //排量列表
      carModelList: [], //车型列表
      carYear: "", //年款值
      carPl: "", //排量值
      userId: "",
    };
  },
  methods: {
    indexBarChange(key) {
      this.$emit('changeLetter',this.indexList, key)      
    },
    scrollBar(key) {
      this.$refs['scrollBar'].scrollTo(key);
    },
    search() {},
    onClickLeft() {
      console.log(this.current);
      switch (this.current) {
        case 4:
          this.carYear = "";
          this.carPl = "";
        case 3:
          this.serviceName = "";
          break;
        case 2:
          this.brandName = "";
          break;
        case 1:
          this.$emit("changeModel", false);
          break;
        default:
          break;
      }
      this.current -= 1;
      this.$emit('rightChar',this.current)
    },
    //点击品牌
    brandClick(item) {
      this.brandName = item.brandName;
      this.brand = item.brandId;
      this.getCarServiceList();
      this.current = 2;
      this.$emit('rightChar',2)
    },
    //点击车系
    seriesClick(item) {
      this.serviceName = item.serviceName;
      this.series = item.serviceId;
      this.getCarModelList();
      this.current = 3;
      this.$emit('rightChar',3)
    },
    //点击车型
    modelClick(item) {
      this.current = 1;
      this.$emit('rightChar',1)
      this.$emit("changeModel", false, item);
    },

    isInViewPortOfOne(el) {
      // viewPortHeight 兼容所有浏览器写法
      const viewPortHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const offsetTop = el.offsetTop;
      const scrollTop = document.documentElement.scrollTop;
      const top = offsetTop - scrollTop;
      //   console.log("top", top);
      // 这里有个+100是为了提前加载+ 100
      console.log(top <= viewPortHeight + 46);
      return top <= viewPortHeight + 100;
    },
    //车型搜索
    getCarModelList() {
      var data = {
        userId: this.userId,
        carServiceId: this.series,
        carYear: this.carYear,
        carPl: this.carPl,
      };
      getCarModelList(data).then((res) => {
        var { carPlList, carYearList, carModelList } = res.data.data;
        var yearList = carYearList.map((val) => {
          return { text: val.carYearName, value: val.carYearName };
        });
        var displacementList = carPlList.map((val) => {
          return { text: val.plName, value: val.plName };
        });
        this.yearList = [{ text: "全部年款", value: "" }, ...yearList];
        this.displacementList = [
          { text: "全部排量", value: "" },
          ...displacementList,
        ];
        this.carModelList = carModelList;
      });
    },
    //车系搜索
    getCarServiceList() {
      var { userId, brand } = this;
      getCarServiceList({ userId, carBrandId: brand }).then((res) => {
        this.seriesList = res.data.data.allList || [];
      });
    },
    //品牌搜索
    getCarBrandList(type) {
      this.current = 1;
      this.$emit('rightChar',1)
      this.userId = sessionStorage.getItem("userId");
      Toast.loading({
        message: "加载中",
        duration: 0,
        forbidClick: true,
      });
      getCarBrandList({ userId: this.userId, type })
        .then((res) => {
          var { firstcharList, arrayAllBrand } = res.data.data;
          this.brandList = arrayAllBrand;
          this.indexList = firstcharList.map((val) => {
            return val.firstchar;
          });
          this.$emit('changeLetter',this.indexList, this.indexList[0])
          Toast.clear();
        })
        .catch((err) => {
          Toast.clear();
        });
    },
    //排量改变
    displacementChange(val) {
      this.carPl = val;
      this.getCarModelList();
    },
    //年款改变
    yearChange(val) {
      this.carYear = val;
      this.getCarModelList();
    },
  },
  mounted() {
    // this.getCarBrandList()
    // window.onscroll = function () {
    //   that.indexList.map((val,index)=>{
    //     var bol = that.isInViewPortOfOne(document.getElementById("aaa" + index));
    //     if(bol){
    //       console.log('aaa'+ index)
    //     }
    //   })

    // };
  },
};
</script>

<style lang="less" scoped>
.choiceCarModel {
  /deep/.van-index-anchor--sticky {
    z-index: 2222;
  }
  /deep/.van-nav-bar {
    z-index: 9999 !important;
  }
  /deep/.van-ellipsis span {
    font-size: 18px;
    font-weight: 500;
  }
  width: 100%;
  padding-top: 50px;
  .cpopup {
    position: relative;
    .close {
      position: absolute;
      left: 15px;
      top: 14px;
      z-index: 2;
      font-size: 20px;
    }
    .brand {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
    .car {
      padding: 5px 15px;
      .title {
        font-size: 16px;
        font-weight: bolder;
      }
      .item {
        line-height: 2.5;

        border-bottom: 1px solid #f8f8f8;
        margin-left: 15px;
        &:last-child {
          border: none;
        }
        .series {
          font-weight: bold;
        }
      }
    }
    /deep/.van-dropdown-menu__bar {
      box-shadow: none;
    }
    .list {
      padding: 10px 15px;
    }
  }
}
</style>